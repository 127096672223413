<template>
  <div
    @click="toggleUserGroup(user)"
    class="bg-white px-3 py-2 flex justify-between items-center cursor-pointer rounded shadow-sm"
  >
    <div class="text-gray-500 flex items-center">
      <ProfileImage
        class="mr-1"
        :user="user"
        :isSmall="true"
        :bgNonWhite="true"
      />
      <div>
        {{ user.name }}
        <div class="text-xs font-semibold -mt-1">
          {{ user.account_type }}
        </div>
      </div>
    </div>
    <i class="fas fa-check-circle text-teal-600" v-if="inGroup" />
    <i class="far fa-circle text-gray-500" v-if="!inGroup" />
  </div>
</template>

<script>
import ProfileImage from "../ProfileImage.vue";
export default {
  name: "UserDisplay",
  props: {
    user: {
      type: Object,
      require: true
    },
    group: {
      type: Object,
      require: true
    },
    groupUserIds: {
      type: Array,
      require: true
    }
  },
  components: {
    ProfileImage
  },
  computed: {
    inGroup() {
      return this.groupUserIds.includes(this.user.id);
    }
  },
  methods: {
    toggleUserGroup(user) {
      this.$store.dispatch("groups/addUsers", { user, group: this.group });
    }
  }
};
</script>
