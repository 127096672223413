<template>
  <div class="md:-mt-12">
    <div class="fixed top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12 z-0" />
    <div class="pt-10 px-8 md:px-14">
      <div class="rounded-t bg-white mb-0 px-6 py-6 relative z-10">
        <div class="text-center flex justify-between">
          <div class="flex">
            <h6 class="text-blueGray-700 text-xl font-bold">
              {{ $t("Groups") }}
            </h6>
            <button
              @click="addNew"
              class="ml-2 bg-green-500 text-white active:bg-emerald-640 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            >
              {{ $t("add group") }}
            </button>
          </div>
        </div>
      </div>
      <div
        style="height: calc(100vh - 255px)"
        class="relative flex flex-col min-w-0 break-words w-full mb-6 mx-auto shadow-lg rounded-lg rounded-t-none bg-blueGray-100 border-0"
      >
        <div class="flex">
          <div class="w-64 border-r">
            <h3 class="px-6 font-semibold mt-3">
              {{ $t("groups") }}
            </h3>
            <div class="overflow-scroll" style="height: calc(100vh - 293px)">
              <!-- <div
                v-if="$store.state.groups.loading"
                class="flex justify-center items-center h-full"
              >
                <i
                  class="fas fa-circle-notch fa-spin mt-4 flex justify-center"
                />
              </div> -->

              <div
                v-for="(g, index) in $store.state.groups.groups"
                :key="g.id"
                class="bg-white px-3 py-2 my-3 mx-4 relative font-semibold cursor-pointer rounded"
                :class="{
                  'border-l-8 border-emerald-600': index == groupIndex
                }"
                @click="selectGroupIndex(index)"
              >
                <i
                  class="far fa-edit text-gray-500 text-sm right-7 top-3 cursor-pointer"
                  @click="editGroup(g)"
                />
                {{ g.name }}
                <el-popconfirm
                  :title="$t('Are you sure to delete this?')"
                  @confirm="removeItem(g)"
                >
                  <i
                    slot="reference"
                    class="far fa-trash-alt text-rose-500 absolute text-sm right-2 top-3"
                  />
                </el-popconfirm>
              </div>
            </div>
          </div>
          <div class="top-0 px-5 py-3" style="width: calc(100% - 16rem)">
            <!-- <div v-if="group != null" class="bg-white px-3 py-2 mb-4">
              <div class="font-semibold">
                {{ $t("users in group") }}: {{ group.name }}
              </div>
              <div v-if="group.users">
                {{ group.users }}
              </div>
            </div> -->
            <h3
              class="font-semibold text-gray-500"
              v-if="selectedGroupByIndex != null"
            >
              {{ selectedGroupByIndex.name }}
            </h3>
            <p
              v-if="selectedGroupByIndex != null"
              class="text-xs text-gray-700 mb-5 -mt-1"
            >
              {{ selectedGroupByIndex.description }}
            </p>
            <div class="overflow-scroll" style="height: calc(100vh - 330px)">
              <div
                v-if="selectedGroupByIndex != null"
                class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"
              >
                <UserDisplay
                  v-for="u in freeUsers"
                  :key="u.id"
                  :user="u"
                  :group="selectedGroupByIndex"
                  :groupUserIds="groupUserIds"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-if="showModal"
      @closeModal="showModal = false"
      :title="$t('group')"
    >
      <div class="bg-gray-100 px-6 py-3">
        <label class="mt-3 text-gray-500 font-semibold inline-block">
          {{ $t("name") }}
        </label>
        <input
          v-model="groupName"
          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        />
        <label class="mt-3 text-gray-500 font-semibold inline-block">
          {{ $t("description") }}
        </label>
        <textarea
          v-model="groupDescription"
          type="text"
          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          rows="4"
        >
        </textarea>
        <button
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs mt-6 px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          @click="handleSave"
        >
          {{ $t("save") }}
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "../components/Modal.vue";
import UserDisplay from "../components/group/UserDisplay.vue";
export default {
  name: "Group",
  components: { Modal, UserDisplay },
  computed: {
    freeUsers() {
      return this.$store.state.users.users.filter(
        user => user.status == "active"
      );
    },
    selectedGroupByIndex() {
      return this.$store.state.groups?.groups
        ? this.$store.state.groups?.groups?.[this.groupIndex]
        : null;
    },
    groupUserIds() {
      return this.selectedGroupByIndex
        ? this.selectedGroupByIndex.users.map(u => u.id)
        : [];
    }
  },
  data() {
    return {
      showModal: false,
      groupName: "",
      groupDescription: "",
      group: null,
      groupIndex: 0
    };
  },
  methods: {
    selectGroupIndex(index) {
      this.groupIndex = index;
    },
    addNew() {
      this.group = null;
      this.showModal = true;
      this.groupName = "";
      this.groupDescription = "";
    },
    editGroup(group) {
      this.group = group;
      this.showModal = true;
      this.groupName = group.name;
      this.groupDescription = group.description;
    },
    removeItem(group) {
      this.$store.dispatch("groups/deleteGroup", { group });
    },
    handleSave() {
      let group = {
        name: this.groupName,
        description: this.groupDescription
      };
      let cb = () => {
        this.groupName = "";
        this.groupDescription = "";
        this.showModal = false;
      };
      if (this.group == null) {
        this.$store.dispatch("groups/createGroup", { group, cb });
      } else {
        this.$store.dispatch("groups/updateGroup", {
          group: {
            ...group,
            id: this.group.id
          },
          cb
        });
      }
    }
  },
  created() {
    this.$store.dispatch("groups/getGroups");
    this.$store.dispatch("users/getAllUsers");
  }
};
</script>
